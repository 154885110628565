<template>
    <div class="quotationOptions">
        <div v-if="quotationSelected">
            <div class="quotation-body__container">
                <!-- quotation header -->
                <div class="quotationBody">           
                    <template v-if="quotationType==='seller'">        
                        <!-- flag -->
                        <div v-if="quotationSelected.flag" class="quotation__flag" :class="quotationSelected.flag.id">
                            <img loading="lazy" :src="quotationSelected.flag.image.url" :title="quotationSelected.flag.image.alt" :alt="quotationSelected.flag.image.alt">
                            <span>{{ quotationSelected.flag.name }} </span>
                        </div>
                    </template>

                    <template v-else>        
                        <!-- flag -->
                        <div v-if="quotationSelected.flag" class="quotation__flag" :class="quotationSelected.flag.id">
                            <img loading="lazy" :src="quotationSelected.flag.image.url" :title="quotationSelected.flag.image.alt" :alt="quotationSelected.flag.image.alt">
                            <span> {{quotationSelected.flag.name }} </span>
                        </div>
                    </template>

                    <!-- TODO colocar endereço no mobile -->
                    <!-- <div class="row leading-lines" v-if="addressModel.dwfrm_shipping_shippingAddress_addressFields_address1">
                        <div class="col-6 start-lines grandTotal">
                            <p class="order-receipt-label grandTotal" ><span>Entregando para:</span></p>
                        </div>
                    </div>
                    <div class="row leading-lines" v-if="addressModel.dwfrm_shipping_shippingAddress_addressFields_address1">
                        <div class="col-12 start-lines">
                            <p>
                                <img src="/on/demandware.static/Sites-SupriJa-Site/-/default/dw21cd2aaa/images/myAccount/iconMyAddress.svg" alt="Address Icon" style="width: 20px; height: 20px; margin-bottom: 4px;">
                                <span style="font-size: 17px !important; text-decoration: underline !important;">
                                    {{ addressModel.dwfrm_shipping_shippingAddress_addressFields_address1 }}, {{ addressModel.dwfrm_shipping_shippingAddress_addressFields_city }} - {{ addressModel.dwfrm_shipping_shippingAddress_addressFields_states_stateCode }}
                                </span>
                            </p>
                        </div>
                    </div> -->
                </div>

                <div v-if="showModal" class="expanded-modal">
                    <div class="expanded-modal-content">
                        <!-- multiseller -->
                        <template v-if="quotationType === 'multiseller'">
                            <div class="modal-body">
                                <div class="productList-container"
                                    v-for="seller in quotationSelected.sellers[0].sellers">
                                    <template v-for="shipment in seller.shipments">
                                        <div class="header-title__store">
                                            <p class="sz-text--large-resp bold store-name__title">{{ shipment.sellerName }}</p>
                                        </div>

                                        <div class="productList-table-header">
                                            <p class="text-center start">Produtos</p>
                                            <p class="text-center d-none d-lg-flex">Quantidade</p>
                                            <p class="text-center d-lg-none">Qtd</p>
                                            <p class="text-center">Preço</p>
                                            <p class="text-center d-none d-lg-flex">Entrega</p>
                                            <p class="text-center minWidth"> Total </p>
                                        </div>
                                        <div style="gap: 12px; display: flex; flex-direction: column;">
                                            <div class="productList-table" v-for="product in seller.items">
                                                <div class="productDetail" v-if="product.images">
                                                    <img class="img-fluid" :src="product.images.small[0].url"
                                                        :alt="product.images.small[0].alt"
                                                        :title="product.images.small[0].title"
                                                        :onerror="'this.src=\'' + urlNoImg + '\''"/>
                                                    <p class="product-name__table">{{ product.productName }}</p>
                                                </div>
                                                <p class="text-center">{{ product.quantity }}</p>
                                                <p class="text-center">{{ product.price.sales.formatted }}</p>
                                                <p class="text-center d-none d-lg-flex">{{
                                                    shipment.deliveryDateFormatted }}</p>
                                                <p class="text-center minWidth">{{ product.priceTotal.price }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>

                        <!-- seller -->
                        <template v-else>
                            <div class="modal-body">
                                <div class="productList-container">
                                    <template v-for="shipment in quotationSelected.shipments">
                                        <div class="header-title__store">
                                            <p class="sz-text--large-resp bold store-name__title">{{ shipment.sellerName }}</p>
                                        </div>

                                        <div class="productList-table-header">
                                            <p class="text-center start">Produtos</p>
                                            <p class="text-center d-none d-lg-flex">Quantidade</p>
                                            <p class="text-center d-lg-none">Qtd</p>
                                            <p class="text-center">Preço</p>
                                            <p class="text-center d-none d-lg-flex">Entrega</p>
                                            <p class="text-center minWidth"> Total </p>
                                        </div>

                                        <div style="gap: 12px; display: flex; flex-direction: column;">
                                            <div class="productList-table"
                                                v-for="product in quotationSelected.items.items">
                                                <div class="productDetail" v-if="product.images">
                                                    <img class="img-fluid" :src="product.images.small[0].url"
                                                        :alt="product.images.small[0].alt"
                                                        :title="product.images.small[0].title"
                                                        :onerror="'this.src=\'' + urlNoImg + '\''"/>
                                                    <p class="product-name__table">{{ product.productName }}</p>
                                                </div>
                                                <p class="text-center">{{ product.quantity }}</p>
                                                <p class="text-center">{{ product.price.sales.formatted }}</p>
                                                <p class="text-center d-none d-lg-flex">{{
                                                     shipment.deliveryDateFormatted }}</p>
                                                <p class="text-center minWidth">{{ product.priceTotal.price }}</p>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routes from '../../../api/routes';
import QuotationModalDetails from './QuotationModalDetails.vue';

export default {
    components: {
        QuotationModalDetails,
    },
    data() {
        return {
            staticUrl: routes.urlStatic,
            showModal: true,
            selectedQuotationDetails: null,
            isHovering: false,
            showModal: true,
        }
    },
    computed: {
        ...mapGetters('checkout', {
            quotationSelected: 'quotationSelected',
            addressModel: 'addressModel'
        }),
        urlNoImg(){
            return this.staticUrl + 'noimage.png';
        },
        quotationType() {
            if (this.quotationSelected)
                return this.quotationSelected.sellers ? 'multiseller' : 'seller'
            return null
        }
    },

}
</script>
