<template>
    <div class="order-total-summary">
        <!--- Subtotal --->
        <div class="row leading-lines subtotal-item">
            <div class="col-6 start-lines">
                <p class="order-receipt-label subTotal"><span class="subTotal">Subtotal</span></p>
            </div>
            <div class="col-6 end-lines">
                <p class="text-right "><span>{{ totals.totalWithoutDiscount.formatted }}</span></p>
            </div>
        </div>

        <div class="row leading-lines coupomCheckout">
            <div class="coupons-and-promos">
                <coupon-display></coupon-display>
            </div>
        </div>

        <!--- Order Discount --->
        <div class="row leading-lines order-discount" :class="totals.grandTotalDiscount.value > 0 ? '' : 'hide-order-discount'">
            <div class="col-6 start-lines">
                <p class="order-receipt-label"><span>Desconto</span></p>
            </div>
            <div class="col-6 end-lines">
                <p class="text-right"><span class="order-discount-total">- {{ totals.grandTotalDiscount.formatted }}</span></p>
            </div>
        </div>


        <!--- Shipping Cost --->
        <div class="row leading-lines shipping-item">
            <div class="col-6 start-lines">
                <p class="order-receipt-label"><span>Frete</span></p>
            </div>
            <div class="col-6 end-lines">
                <p class="text-right">
                    <span :class="{'text-green': totals.totalShippingCost.formatted.trim() === 'Grátis'}" class="shipping-total-cost">
                        {{ totals.totalShippingCost.formatted }}
                    </span>
                </p>
            </div>
        </div>

        <!--- Shipping Discount --->
        <template v-if="totals.shippingLevelDiscountTotal.value !== 0 && totals.totalShippingCost.value !== 0">
            <div class="row leading-lines shipping-discount" :class="totals.shippingLevelDiscountTotal.value === 0 ? 'hide-shipping-discount' : ''">
                <div class="col-6 start-lines">
                    <p class="order-receipt-label"><span>Desconto do Frete</span></p>
                </div>
                <div class="col-6 end-lines">
                    <p class="text-right"><span class="shipping-discount-total">- {{ totals.shippingLevelDiscountTotal.formatted }}</span></p>
                </div>
            </div>
        </template>

        <!--- Sales Tax --->
        <!-- <div class="row leading-lines sales-tax-item">
            <div class="col-6 start-lines">
                <p class="order-receipt-label"><span>Taxa de venda</span></p>
            </div>
            <div class="col-6 end-lines">
                <p class="text-right"><span class="tax-total">{{ totals.totalTax }}</span></p>
            </div>
        </div> -->

        <!--- Grand Total --->
        <div class="row leading-lines">
            <div class="col-6 start-lines grandTotal">
                <p class="order-receipt-label grandTotal" ><span>Total</span></p>
            </div>
            <div class="col-6 end-lines grandTotal">
                <p class="text-right"><span class="sz-text--large bold grand-total grand-total-sum">{{ totals.grandTotal.formatted }}</span></p>
            </div>
        </div>
        <br>
        <div class="row leading-lines" v-if="addressModel.dwfrm_shipping_shippingAddress_addressFields_address1">
            <div class="col-6 start-lines grandTotal">
                <p class="order-receipt-label grandTotal" ><span>Entregando para:</span></p>
            </div>
        </div>
        <div class="row leading-lines" v-if="addressModel.dwfrm_shipping_shippingAddress_addressFields_address1">
            <div class="col-12 start-lines">
                <p>
                    <img :src="iconMyAddress" title="Ícone do endereço" alt="Ícone do endereço" style="width: 20px; height: 20px; margin-bottom: 4px;">
                    <span style="font-size: 17px !important; text-decoration: underline !important;">
                        {{ addressModel.dwfrm_shipping_shippingAddress_addressFields_address1 }}, {{ addressModel.dwfrm_shipping_shippingAddress_addressFields_city }} - {{ addressModel.dwfrm_shipping_shippingAddress_addressFields_states_stateCode }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import routes from '../../../api/routes';
    import CouponDisplay from './CouponDisplay';

    export default {
        components: {
            CouponDisplay
        },
        data() {
            return {
                staticUrl: routes.urlStatic
            }
        },
        computed: {
            ...mapGetters('totals', {
                totals: "totals",
            }),
            ...mapGetters('checkout', {
                addressModel: 'addressModel',
            }),
            iconMyAddress(){
                return this.staticUrl + '/myAccount/iconMyAddress.svg';
            }
        },
        watch: {
            'totals.totalShippingCost.formatted': function(newVal, oldVal) {
                console.log('Shipping Cost formatted changed from', oldVal, 'to', newVal);
            }
        },
        created() {
            console.log('Initial Shipping Cost formatted:', this.totals.totalShippingCost.formatted);
        },
        props: [],
    }
</script>

<style scoped>
.text-green {
    color:#00744A;
}
</style>





