'use strict';

$(document).ready(function () {
    var modalLogin= $('.myAccountHeader-unlogged')
    if (window.location.href.indexOf('rurl=login') !== -1) {
        modalLogin.removeClass('d-none').hide().fadeIn(300);
        $('.menu-toggleable-left').css('z-index','0');
        $('.login-modal-background').removeClass('d-none').hide().fadeIn(300);
    }
    $('.user-loginModal').click(function() {
        if(modalLogin.hasClass('d-none')){
            modalLogin.removeClass('d-none').hide().fadeIn(300);
            $('.login-modal-background').removeClass('d-none').hide().fadeIn(300);
        }
        $('.menu-toggleable-left').css('z-index','0');
    });

    // close login modal if outside click
    $(document).on('click', function (e) {
        var $modal = $('.myAccountHeader-options');
        var $modalOpener = $('.user-loginModal');
        var $closeButton = $('#accModal .close.btn-close');

        if (!$modal.hasClass('d-none') && !$modal.is(e.target) && $modal.has(e.target).length === 0 && !$modalOpener.is(e.target) && $modalOpener.has(e.target).length === 0) {
            $closeButton.click();
        }
    });

    $('.containerLogin .btn-close').click(function() {
        modalLogin.fadeOut(300, function() {
            $(this).addClass('d-none');
            $('.login-modal-background').addClass('d-none');
            $('.menu-toggleable-left').css('z-index','inherit');
        });
    });

    if (window.location.href.indexOf('rurl=2') !== -1 || window.location.href.indexOf('rurl=4') !== -1) {
        $('#userGuest-tab').css('display', 'block');
    }
    $('#profileBirthday').mask('00/00/0000');
    $('.registration-form-cpf').mask('000.000.000-00', { reverse: true });
    $('#registration-form-cnpj').mask('00.000.000/0000-00', { reverse: true });
    // $('#registration-form-stateRegistration').mask('000.000.000.000', { reverse: true });


    var maskBehavior = function (val) {
        var numericVal = val.replace(/\D/g, '')

        var hasInvalidPattern = /^(11)1111-1111|^(22)2222-2222|^(33)3333-3333|^(44)4444-4444|^(55)5555-5555|^(66)6666-6666|^(77)7777-7777|^(88)8888-8888|^(99)9999-9999/.test(val);

        var hasRepeatingNumbers = /(0{6,}|1{6,}|2{6,}|3{6,}|4{6,}|5{6,}|6{6,}|7{6,}|8{6,}|9{6,})/.test(numericVal);

        var hasSpecificPattern = /^\d{5}-\d{4}$/.test(val);

        var hasRepeatedPairs = /(\d)\1{5,}|(01){4,}|(10){4,}|(02){4,}|(20){4,}|(03){4,}|(30){4,}|(04){4,}|(40){4,}|(05){4,}|(50){4,}|(06){4,}|(60){4,}|(07){4,}|(70){4,}|(08){4,}|(80){4,}|(09){4,}|(90){4,}|(12){4,}|(21){4,}|(13){4,}|(31){4,}|(14){4,}|(41){4,}|(15){4,}|(51){4,}|(16){4,}|(61){4,}|(17){4,}|(71){4,}|(18){4,}|(81){4,}|(19){4,}|(91){4,}|(23){4,}|(32){4,}|(24){4,}|(42){4,}|(25){4,}|(52){4,}|(26){4,}|(62){4,}|(27){4,}|(72){4,}|(28){4,}|(82){4,}|(29){4,}|(92){4,}|(34){4,}|(43){4,}|(35){4,}|(53){4,}|(36){4,}|(63){4,}|(37){4,}|(73){4,}|(38){4,}|(83){4,}|(39){4,}|(93){4,}|(45){4,}|(54){4,}|(46){4,}|(64){4,}|(47){4,}|(74){4,}|(48){4,}|(84){4,}|(49){4,}|(94){4,}|(56){4,}|(65){4,}|(57){4,}|(75){4,}|(58){4,}|(85){4,}|(59){4,}|(95){4,}|(67){4,}|(76){4,}|(68){4,}|(86){4,}|(69){4,}|(96){4,}|(78){4,}|(87){4,}|(79){4,}|(97){4,}|(89){4,}|(98){4,}/.test(numericVal);

        var isValidMobile = numericVal.length === 11;
        var isValidLandline = numericVal.length === 10 && numericVal[2] !== '9';

        var isValidDDD = /^(11|12|13|14|15|16|17|18|19|21|22|24|27|28|31|32|33|34|35|37|38|41|42|43|44|45|46|47|48|49|51|53|54|55|61|62|63|64|65|66|67|68|69|71|73|74|75|77|79|81|82|83|84|85|86|87|88|89|91|92|93|94|95|96|97|98|99)/.test(numericVal.substring(0, 2));

        if (!isValidLandline && isValidMobile && isValidDDD && !hasInvalidPattern && !hasRepeatingNumbers && !hasSpecificPattern && !hasRepeatedPairs) {
            return '(00) 00000-0000'

        }else if (isValidLandline && !isValidMobile && isValidDDD && !hasInvalidPattern && !hasRepeatingNumbers && !hasSpecificPattern && !hasRepeatedPairs){
            return '(00) 0000-0000'
        }
         else {
            return '(00) 0000-00000'
        }
    }
    
       var telOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(maskBehavior.apply({}, arguments), options);
            }
        };
    $('#registration-form-phone').mask(maskBehavior, telOptions);
    $('#registration-form-phone2').mask(maskBehavior, telOptions);

    $('.loginPage-btn,.js-registerPageBtn').on('click', function (event) {
        let form = $(this).closest('form');
        let fields = form.find('.form-group input');
        let isFormValid = true; 
    
        fields.each(function () {
            let inputValue = $(this).val();
            if (inputValue.length < 1 && $(this).attr('required')) {
                $(this)
                    .addClass('is-invalid')
                    .siblings('.invalid-feedback')
                    .html('Preencha esse campo');
                isFormValid = false;
            } else if ($(this).attr('id') === 'registration-form-phone' || $(this).attr('id') === 'registration-form-phone2') {
                let isValidPhone = maskBehavior(inputValue) !== '(00) 0000-00000';
                if (!isValidPhone) {
                    $(this)
                        .addClass('is-invalid')
                        .siblings('.invalid-feedback')
                        .html('Telefone inválido');
                    isFormValid = false; 
                } else {
                    $(this)
                        .removeClass('is-invalid')
                        .siblings('.invalid-feedback')
                        .html('');
                }
            } else {
                $(this)
                    .removeClass('is-invalid')
                    .siblings('.invalid-feedback')
                    .html('');
            }
        });
    
        if (!isFormValid) {
           event.preventDefault(); 
           return false; 
        }
    });
    

    $('form .form-group input').on('keypress', function () {
        let inputValue = $(this).val();
        if (inputValue.length > 0) {
            $(this)
                .removeClass('is-invalid')
                .siblings('.invalid-feedback')
                .html('');
        }
    });
});

$('body').on('submit', '#guest-customer', function (e) {
    e.preventDefault();
    var form = $(this);

    var url = form.attr('action');
    form.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.error) {
                location.href = data.redirectUrl;
            } else {
                $('#email-guest')
                    .siblings('.invalid-feedback')
                    .empty()
                    .append(data.errorMessage);
            }
        },
        error: function (err) {
            form.spinner().stop();
        }
    });
    return false;
});