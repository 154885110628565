<template>
    <div v-if="renderComponent" class="quantityAndShop">
        <input type="hidden" class="add-to-cart-url" :value="urlAddCart" />
        <button
            class="quickBuyBtn add-to-cart-carousel"
            :data-pid="id"
            :id="`quickBuyBtn-${id}`"
            :disabled="!productAvailable">
            Adicionar ao Carrinho
        </button>

        <div class="qtdContainer productCard" :id="`productCard-${id}`">
            <div class="quantity-form-tile js-quantity-form-tile">
                <button class="sz-text--largest-resp decreaseAmountCart js-decreaseAmountCart" type="button"> - </button>
                <input class="quantity" type="number" :value="quantityFormat" :id="`quantity-${uuid}`"
                    :data-uuid="uuidLineItem" :data-pid="id" :data-action="urlUpdateQnt" :data-remove="urlRemoveProduct"
                    :data-pre-select-qty="quantity" :name="`quantity-${uuid}`" />
                <button class="sz-text--largest-resp increaseAmountCart js-increaseAmountCart" type="button"> + </button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        id: {
            default: null,
            type: String
        },
        uuid: {
            default: null,
            type: String
        },
        uuidLineItem: {
            default: null,
            type: String
        },
        productAvailable: {
            default: null,
            type: Boolean
        },
        quantity: {
            default: 0,
            type: Number
        },
        urlAddCart: {
            default: '',
            type: String
        },
        urlUpdateQnt: {
            default: '',
            type: String
        },
        urlRemoveProduct: {
            default: '',
            type: String
        },
        showQnt: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            quantityFormat: this.quantity,
            renderComponent: true,
        };
    },

    mounted() {
        this.toggleVisibility();
    },

    methods: {
        toggleVisibility() {
            if (this.quantity === 0) {
                this.$nextTick(() => {
                    this.$el.querySelector('.summary .productCard').classList.add('d-none');
                });
            } else if (this.quantity > 0) {
                this.$nextTick(() => {
                    this.$el.querySelector('.summary button.add-to-cart-carousel').classList.add('d-none');
                });
            }
        }
    },

    beforeDestroy() {
    }
};
</script>
