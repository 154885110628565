<template>
    <div class="product-tile-pd product-page-pdp col">
        <div class="productTileBox">
            <picture>
                <div class="product-tile-image">
                    <a :href="product.selectedProductUrl" class="product-tile-image-link" :title="product.images.small[0].title">
                        <img loading="lazy" class="product-tile-component-image lazyload"
                            :data-src="product.images.small[0].url" :alt="product.productName"
                            :title="product.images.small[0].title" width="120" height="120"
                            :onerror="'this.src=\'' + urlNoImg + '\''"/>
                    </a>
                </div>
            </picture>

            <div class="product-tile-body">
                <div class="product-name-link">
                    <h3><a class="sz-text--small" :href="product.selectedProductUrl" :title="product.productName">{{ product.productName }}</a></h3>
                </div>
                <div class="component-price-buttom">
                    <div class="sz-text--small productTile__price--container">
                        <div class="sz-text--smallest bold line-item-total-text unit-price-label">
                            A partir de
                        </div>
                        <div class="product-price sz-text--large-resp bold">
                            <span class="range">
                                <div class="price-content-tile">
                                    <span v-if="product.promo && showBtn" class="price">{{ product.promo.promotionPriceFormatted }}</span>
                                    <span v-else class="sales">
                                        <span class="value d-none" :content="product.price.min ? product.price.min.sales.decimalPrice : product.price.sales.decimalPrice"></span>
                                        {{ product.price.min ? product.price.min.sales.formatted : product.price.sales.formatted }}
                                    </span>
                                </div>
                            </span>
                            <div class="d-flex align-items-center">
                                <div class="price">
                                    <span class="strike-through list">
                                        <template v-if="product.promo && showBtn">
                                            <!-- {{ product.price.min ? product.price.min.sales.formatted : product.price.sales.formatted }} -->
                                            {{ product.promo.defaultPriceFormatted }}
                                        </template>
                                    </span>
                                </div>
                                <!-- tag de promocao -->
                                <span v-if="product.promo" class="promotion_tag">{{product.promo.promotionPercentage}}% OFF</span>
                            </div>
                        </div>
                    </div>

                    <template v-if="showBtn === true">
                        <template v-if="showQnt === true">
                            <product-quantity
                                :id="product.id"
                                :uuid="product.uuid"
                                :quantity="product.quantity"
                                :uuidLineItem="product.uuidLineItem"
                                :productAvailable="product.available"
                                :urlAddCart="urlAddCart"
                                :urlUpdateQnt="urlUpdateQnt"
                                :urlRemoveProduct="urlRemoveProduct"
                                use="PDP"
                            >
                            </product-quantity>
                        </template>
                        <template v-if="showQnt === false">
                            <product-add
                                :id="product.id"
                                :uuid="product.uuid"
                                :quantity="product.quantity"
                                :uuidLineItem="product.uuidLineItem"
                                :productAvailable="product.available"
                                :urlAddCart="urlAddCart"
                                use="PDP"
                            >
                            </product-add>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductQuantity from './ProductQuantity';
import ProductAdd from './ProductAdd';
import routes from '../../../api/routes';

export default {
    props: ['product', 'showQnt', 'showBtn', 'urlAddCart', 'urlUpdateQnt', 'urlRemoveProduct'],
    components: {
        ProductQuantity,
        ProductAdd
    },
    data() {
        return {
            staticUrl: routes.urlStatic,
        }
    },
    computed: {
        urlNoImg(){
        return this.staticUrl + 'noimage.png';
        }
    }
}
</script>

<style>
    .product-page-pdp .productTileBox {
        margin-top: 0px !important;
    }
</style>
