'use strict';
const { showAlert, addUrlParam } = require('../helpers/handlers');

function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost.formatted);
    $('.tax-total').empty().append(data.totals.totalTax.formatted);
    $('.grand-total').empty().append(data.totals.grandTotal.formatted);
    $('.sub-total').empty().append(data.totals.subTotal.formatted);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.grandTotalDiscount.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.grandTotalDiscount.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append('<div class="email-signup-message"></div>');
    }
    $('.email-signup-message').append(
        '<div class="email-signup-alert text-center ' +
            status +
            '">' +
            data.msg +
            '</div>'
    );

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}
function addProductlist() {
    $('.addProductList-form').on('submit', function (e) {
        e.preventDefault();
        $('#submit-productListForm').addClass("is__loading");

        var formEl = $(this);
        var url = $(this).attr('data-action');
        var isMyAccount =
            $('body').find('.newList__wrapper.myAccount').length > 0;
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $(formEl).serialize(),
            success: function (data) {
                $('#submit-productListForm').removeClass('is__loading');

                if (isMyAccount) {
                    location.reload(); // reload only in my account
                } else {
                    $('.js-addToListSelect').append(
                        '<option id="' +
                            data.listData.UUID +
                            '" value="' +
                            data.listData.UUID +
                            '">' +
                            data.listData.name +
                            '</option>'
                    );
                    $(formEl).trigger('reset');

                    $('#addListModal').modal('hide');
                    $('#wishlistModal').modal('show');
                    $('.add-product-to-product-list-btn').addClass('enabled');
                }

                if ($(formEl).parents('.productlist__list').length > 0) {
                    addUrlParam('selectedList', data.listData.UUID);
                }
            },
            error: function (err) {
                $('#submit-productListForm').removeClass('is__loading');

                if (err.responseJSON.message) {
                    showAlert(formEl, 'error', err.responseJSON.message);
                }
            }
        });
    });

    $('.create-list-btn').on('click', function (e) {
        $('#addListModal').modal('show');
    });

    $('body').on('click', '.addlist-productlist-btn', function (e) {
        var modalDisplay = $(this).parent().data('display');
        if (modalDisplay) {
            // in my account
            $(`.addList-${modalDisplay} .add-list-modal`).modal('show');
        } else {
            // in PDP
            $('#wishlistModal').modal('hide');
            $('.product-detail .add-list-modal').modal('show');
        }
    });
    $('.create-list-cancel').on('click', function (e) {
        $('#wishlistModal').modal('show');
    });
}

function getProductlist() {
    $(document).ready(() => {
        var firstList = $('.listRadioInput')[0];
        $(firstList).addClass('active').prop('checked');
    });
    $('.productlist-list-item').click(function (e) {
        e.preventDefault();
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        $('body').spinner().start();
        $('.listRadioInput').removeClass('active');
        $(this)
            .children('.listRadioInput')
            .prop('checked', true)
            .addClass('active');
        $.ajax({
            url: url,
            type: 'get',
            data: {
                uuid: uuid
            },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    $('.selected-productlist')
                        .empty()
                        .append(data.renderedListItems);
                    $('.summary-productlist-body').empty();
                }
                $('body').spinner().stop();
            },
            error: function (err) {
                $('body').spinner().stop();
                // Define error method and redirects
            }
        });
    });
    $('.display-selected-list-mobile select').on('change', function (e) {
        // only triggers in my account
        e.preventDefault();
        var url = $(this).data('action');
        var uuid = $(this).val();
        $('body').spinner().start();
        $('.listRadioInput').removeClass('active');
        $(`.listRadioInput[value=${uuid}]`)
            .prop('checked', true)
            .addClass('active');
        $.ajax({
            url: url,
            type: 'get',
            data: {
                uuid: uuid
            },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    $('.selected-productlist')
                        .empty()
                        .append(data.renderedListItems);
                    $('.summary-productlist-body').empty();
                }
                $('body').spinner().stop();
            },
            error: function (err) {
                $('body').spinner().stop();
                // Define error method and redirects
            }
        });
    });
}
function editProductList() {
    $('body').on('click', '.edit-list-btn', function () {
        var listUUID = $(this).data('uuid');
        $('#listUUID').val(listUUID);
        // console.log("🚀 ~ editProductList edit-list-btn listUUID: ", listUUID)
    });

    $('#editListName').on('submit', function (e) {
        e.preventDefault();
        const url = $(this).attr('action');
        const uuid = $('#listUUID').val();
        const name = $('#listName').val();
        if (!name) {
            $('#listName')
                .addClass('is-invalid')
                .siblings('.invalid-feedback')
                .html('O nome da lista não pode ser vazio.');
        } else {
            $('body').spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                data: {
                    uuid: uuid,
                    name: name
                },
                dataType: 'json',
                success: function (data) {
                    $('body').spinner().stop();
                    window.location.reload();
                },
                error: function (error) {
                    $('body').spinner().stop();
                },
            });
        }
    });
}
function deleteProductlist() {
    $('body').on('click', '.cancel-deletelist-btn', function () {
        $('#confirmDeleteListModal').modal('hide');
        $('#editListModal').modal('show');
    });
    $('body').on('click', '.remove-productlist-btn', function () {
        $('#editListModal').modal('hide');
        $('#confirmDeleteListModal').modal('show');
    });
    $('body').on('click', '.confirm-deletelist-btn', function (e) {
        e.preventDefault();
        var url = $(this).data('action');
        var uuid = $('#listUUID').val();

        $('body').spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            data: {
                uuid: uuid
            },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    location.reload();
                }
                $('body').spinner().stop();
            },
            error: function (err) {
                $('body').spinner().stop();
                // Define error method and redirects
            }
        });
    });
    $('body').on('click', '.remove-product-list', function (e) {
        e.preventDefault();
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        $('body').spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            data: {
                uuid: uuid
            },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    $('body').removeClass('modal-open');
                    location.reload();
                }
                $('body').spinner().stop();
            },
            error: function (err) {
                $('body').spinner().stop();
                // Define error method and redirects
            }
        });
    });
}

function addProductToProductlist() {
    $('body').on('change', '.product-list.custom-select', function () {
        $(this).removeClass('is-invalid');
    });
    $('.add-product-to-product-list-btn').on('click', function (e) {
        e.preventDefault();
        $.spinner().start();
        var url = $(this).data('action');
        var uuid = $('body').find('.product-list').val();
        var pid = $('.add-product-to-product-list-form')
            .find('#product-id')
            .val();
        var qty = $('body').find(`.quantity-range-${pid}`).val();
        var form = {
            uuid: uuid,
            pid: pid,
            qty: qty
        };

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form,
            success: function (data) {
                if (data.success) {
                    //datalayer trigger add_to_wishlist
                    $('body').trigger('dataLayerPush:event', data.add_to_wishlist);

                    displayMessage(data, $(this));
                    $('#wishlistModal').modal('hide');
                } else {
                    $('.product-list.custom-select')
                        .addClass('is-invalid')
                        .siblings('.invalid-feedback')
                        .html(data.msg);
                }
                $.spinner().stop();
            },
            error: function (err) {
                $.spinner().stop();
                // displayMessage(err, button);
            }
        });
    });
    $('body').on('click', '.add-product-to-product-list', function () {
        // open modal and sets current product id in modal's input
        $('#wishlistModal').modal('show');
        $('#product-id').val($(this).data('pid'));
    });
    $('body').on('click', '.close-wishlist', function () {
        $('.addToWishlistModal').modal('hide');
        $('.product-list.custom-select').removeClass('is-invalid');
    });
    $('#wishlistModal').on('hidden.bs.modal', function () {
        $('.product-list.custom-select').removeClass('is-invalid');
    });
}

function removeFromProductlist() {
    $('body').on('click', '.remove-from-productlist', function (e) {
        var url = $(this).data('url');
        var uuid = $(this).data('uuid');
        var pid = $(this).data('pid');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            data: {
                url,
                pid,
                uuid
            },
            dataType: 'json',
            success: function (data) {
                var toRemove;
                if (data.toDelete.deleteSeller) {
                    toRemove = '.seller-id-' + data.toDelete.id;
                } else {
                    toRemove = '.pid-' + data.toDelete.id;
                }

                $(toRemove).remove();
                if (data.toDelete.isLastItem) {
                    $('.mv-sellers-list').html(data.toDelete.message);
                    $('.selected-productlist')
                        .empty()
                        .append(data.renderedEmptyList);
                } else {
                    $(`.productlist-itemCard-${data.toDelete.id}`)
                        .next('.lineItemDivider')
                        .remove();
                    $(`.productlist-itemCard-${data.toDelete.id}`).remove();
                }

                // updateTotals(data.toDelete.summary)

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
                var $elToAppend = $('.productlist-account-card');
                var msg = $elToAppend.data('error-msg');
                // displayErrorMessage($elToAppend, msg);
            }
        });
    });
}

function updateProductQtyToProductList() {
    $('body').on('submit', '.update-quantity', function (e) {
        e.preventDefault();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: $(this).attr('action'),
            type: 'post',
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                $.spinner().stop();
                // displayMessage(data, button);
            },
            error: function (err) {
                $.spinner().stop();
                // displayMessage(err, button);
            }
        });
    });
}

function generateShoppingList() {
    $('body').on('submit', '.generate-shoppinglist', function (e) {
        e.preventDefault();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: $(this).attr('action'),
            type: 'get',
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                    $('.productlistItemCards')
                        .empty()
                        .append(data.renderedShoppingListItems);
                    $('.summary-productlist-body').empty().append(data.summary);
                }
                // displayMessage(data, button);
            },
            error: function (err) {
                $.spinner().stop();
                // displayMessage(err, button);
            }
        });
    });
}

function shoppingListAddProductToCart() {
    $('body').on('submit', '.productlist-add-product-to-cart', function (e) {
        e.preventDefault();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: $(this).attr('action'),
            type: 'post',
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                }
                // displayMessage(data, button);
            },
            error: function (err) {
                $.spinner().stop();
                // displayMessage(err, button);
            }
        });
    });
}

function shoppingListAddSellerToCart() {
    $('body').on('submit', '.productlist-add-seller-to-cart', function (e) {
        e.preventDefault();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: $(this).attr('action'),
            type: 'post',
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                $.spinner().stop();
                if (data.success) {
                }
                // displayMessage(data, button);
            },
            error: function (err) {
                $.spinner().stop();
                // displayMessage(err, button);
            }
        });
    });
}

function addSelectedProductItemsToCart() {
    $('body').on('click', '.decreaseAmountProductList', function () {
        $('.decreaseAmountProductList, .increaseAmountProductList').css(
            'pointer-events',
            'none'
        );
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        $('.decreaseAmountProductList, .increaseAmountProductList').css(
            'pointer-events',
            'auto'
        );
        return false;
    });
    $('body').on('click', '.increaseAmountProductList', function () {
        $('.decreaseAmountProductList, .increaseAmountProductList').css(
            'pointer-events',
            'none'
        );
        var $input = $(this).parent().find('input.productlistItemQuant');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        $('.decreaseAmountProductList, .increaseAmountProductList').css(
            'pointer-events',
            'auto'
        );
        return false;
    });
    $('body').on('change', '.productlistItemQuant', function () {
        var url = $(this).data('action');
        var pid = $(this).data('pid');
        var qty = $(this).val();
        var listItemID = $(this).data('list');
        var uuid = $(this).data('uuid');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid,
                qty,
                uuid,
                listItemID
            },
            success: function (data) {
                if (!data.error) {
                    $(`.plProductCard-subTotal-${uuid} span`)
                        .empty()
                        .append(data.updatedProduct.subTotal.formatted);
                }
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $('body').on('click', '#addSelectedItemsToCart', function () {
        var pidsObj = [];

        $('.selectedProductListItems').each(function () {
            if ($(this).prop('checked')) {
                var pid = $(this).data('pid');
                var qty = $(`.productlistItemQuant-${pid}`).val();
                pidsObj.push({
                    pid,
                    qty
                });
            }
        });

        var url = $(this).data('action');
        var products = JSON.stringify(pidsObj);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: { products },
            success: function (data) {
                if (!data.error) {
                   updateCartTotals(data.cart);
                    $('body').trigger('cart:update');
                    if($(window).width() <= 992){
                        $('body').trigger('miniCartOpenMobile:event')
                    }
                }
                displayMessage(data, $(this));
            },
            error: function (err) {
                console.log('>>>', err);
            }
        });
    });
    
    $('body').on('change', '.selectedProductListItems', function () {
        var hasCheckedItems = false;

        $('.selectedProductListItems').each(function () {
            if ($(this).prop('checked')) {
                hasCheckedItems = true;
            }
        });
        if (hasCheckedItems) {
            $('.addToCart').fadeIn();
        } else {
            $('.addToCart').fadeOut();
        }
    });

    $('body').on('change', '.selectAllProducts', function (e) {
        if ($(this).prop('checked')) {
            $('.selectedProductListItems').prop('checked', true).change();
        } else {
            $('.selectedProductListItems').prop('checked', false).change();
        }
    });
}

function moveProductToSeletedList() {
    $('body').on('click', '.move-product-cancel', function () {
        $('#moveProductModal').modal('hide');
    });
    $('body').on('click', '.move-product-from-productlist', function (e) {
        $('#moveProductModal').modal('show');
        $('#selectedListId').removeClass('is-invalid');
        var uuid = $(this).data('uuid');
        var pid = $(this).data('pid');
        $('body').find('#movingProductId').val(pid);
        $('body').find('#currentListUUID').val(uuid);
    });
    $('body').on('submit', '#moveProductToSelectedList', function (e) {
        e.preventDefault();
        var url = $(this).data('action');
        var pid = $('#movingProductId').val();
        var listId = $('#currentListUUID').val();
        var destinationListId = $('#selectedListId').val();
        $.ajax({
            url: url,
            type: 'post',
            data: {
                pid,
                listId,
                destinationListId
            },
            dataType: 'json',

            success: function (data) {
                if (!data.error) {
                    if (data.isLastItem) {
                        $('.selected-productlist')
                            .empty()
                            .append(data.renderedEmptyList);
                    } else {
                        $(`.productlist-itemCard-${data.pid}`)
                            .next('.lineItemDivider')
                            .remove();
                        // must remove divider first
                        $(`.productlist-itemCard-${data.pid}`).remove();
                    }

                    $('#moveProductModal').modal('hide');
                    displayMessage(data);
                } else {
                    $('#selectedListId')
                        .addClass('is-invalid')
                        .siblings('.invalid-feedback')
                        .html(data.msg);
                }
            },
            error: function (err) {}
        });
    });
    $('body').on('change', '#selectedListId', function () {
        $(this).removeClass('is-invalid');
    });
}

module.exports = {
    moveProductToSeletedList,
    addSelectedProductItemsToCart,
    addProductToProductlist,
    removeFromProductlist,
    updateProductQtyToProductList,
    addProductlist,
    getProductlist,
    editProductList,
    deleteProductlist,
    generateShoppingList,
    shoppingListAddProductToCart,
    shoppingListAddSellerToCart
};
