<template>
   <div class="quotationPage">

        <h1 class="sz-heading--large container quotationPage-title d-flex flex-row justify-content-between align-items-center">
            Escolha a melhor oferta
            <quotation-share></quotation-share>
        </h1>

        <div class="container">
            <quotation-all-store
                :quotationModel="quotationMethods">
            </quotation-all-store>
        </div>

        <quotation-share-modal-info v-show="isShareQuotation"></quotation-share-modal-info>
        <request-password-reset-modal v-show="showResetForm"></request-password-reset-modal>

   </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import QuotationAllStore from './quotation/QuotationAllStore';
    import QuotationShare from './quotation/QuotationShare';
    import QuotationShareModalInfo from './quotation/QuotationShareModalInfo';
    import RequestPasswordResetModal from './account/RequestPasswordResetModal';

    export default {
        components: {
            QuotationAllStore,
            QuotationShare,
            QuotationShareModalInfo,
            RequestPasswordResetModal
        },

        props: [],

        computed: {
            ...mapGetters('checkout', {
                quotationMethods: 'quotationMethods',
                isShareQuotation: 'isShareQuotation',
            }),

            ...mapGetters('account', {
                showResetForm: 'showResetForm',
            }),
        }
    }
</script>