<template>
    <div>
        <template v-for="discount in totals.discounts">
            <template v-if="discount.type === 'coupon'">
                <div :class="'coupon-price-adjustment coupon-uuid-' + discount.UUID" :data-uuid="discount.UUID">
                    <span v-if="discount.applied" class="coupon-applied">Cupom ativo</span>
                    <span v-else class="coupon-not-applied">Não aplicado</span>

                    <div class="coupon-code">{{ discount.couponCode }}
                        <button type="button" class="float-right btn-remove-coupon"
                            :data-code="discount.couponCode"
                            :aria-label="'Remover cupom' + discount.couponCode"
                            data-toggle="modal"
                            data-target="#alertRemoveCouponModal"
                            :data-uuid="discount.UUID"
                            @click="setCouponUUID(discount.UUID, discount.couponCode)">
                            <span aria-hidden="true" class="removeCoupomIcon icon-close"></span>
                        </button>
                    </div>
                </div>
                <p class="errorRemoveCoupon" v-if="couponError === true && removeUUID === discount.UUID"> {{ couponErrorMsg }} </p>

            </template>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import RemoveCouponModal from './RemoveCouponModal';

    export default {
        components: {
            RemoveCouponModal
        },

        computed: {
            ...mapGetters('totals', {
                totals: "totals",
            }),
        },

        props: [],

        data() {
            return {
                removeUUID: '',
                couponErrorMsg: '',
                couponError: false,
            };
        },

        created() {
            this.$root.$refs.CouponDisplay = this;
        },

        methods: {
            setCouponUUID(couponUuid, couponCode) {
                this.removeUUID = couponUuid;
                const data = {
                    couponUuid: couponUuid,
                    couponCode: couponCode
                };
                this.$store.commit('checkout/setCoupon', data);
            },
            async removeCoupon(uuid) {
                this.$root.$refs.CouponDisplay.couponErrorMsg = '';
                this.$root.$refs.CouponDisplay.couponError = false;

                if(uuid){
                    const resultRemoveCoupon = await this.$store.dispatch('checkout/removeCoupon', uuid);

                    if (resultRemoveCoupon.hasOwnProperty('error') && resultRemoveCoupon.error) {
                        this.$root.$refs.CouponDisplay.couponErrorMsg = resultRemoveCoupon.errorMessage;
                        this.$root.$refs.CouponDisplay.couponError = true;
                    }else{
                        this.$root.$refs.Coupon.resetCouponField();
                    }

                    this.$root.$refs.CouponDisplay.removeUUID = '';
                }
            }
        }
    }
</script>

<style scoped>
    .errorRemoveCoupon{
        font-size: 12px;
        color:  #c00;
    }
    .btn-remove-coupon{
        border: 0px;
        background: transparent;
    }
    .removeCoupomIcon{
        color: #D0D0D0;
        font-size: 18px;
    }
</style>

